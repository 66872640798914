import Vue from 'vue'
import Vuex from 'vuex'
import createPresistedState from 'vuex-persistedstate'

Vue.use(Vuex)

import { UserStore } from "./modules/user/store.js"
import { WorkflowStore } from "./modules/workflow/store.js"
import { DashboardcomposerStore } from "./modules/dashboardcomposer/store.js"
import { QuestionnaireStore } from "./modules/questionnaire/store.js"
import { SensorStore } from "./modules/sensor/store.js"
import { EHRStore } from './modules/ehr/store.js'

//export const store = () => {
//    return new Vuex.Store({
  export const store = new Vuex.Store({
      modules: {
        DashboardcomposerStore,
        UserStore,
        WorkflowStore,
        QuestionnaireStore,
        SensorStore,
        EHRStore
      },
      plugins: [createPresistedState()]
    });
  //};