<template>
    <survey :survey="survey"></survey>
</template>


<!--
Survey rendering (surveyJS) component

This component renders a questionnaire provided in JSON.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
--> 

<script>
import i18n from "@/plugins/i18n";
import { Survey, Model } from "survey-vue";

// style
import surveyTheme from "../../../assets/json/surveyTheme.json";

export default {
  name: "FillAnonymousQuestionnairePage",
  props: ["survId", "jsondata_quest", "jsondata_img"], //, "anonymous_token"],, "questId"
  components: {
    Survey,
  },
  data() {
    const survey = this.initialiseSurvey();
    this.setSurveyImages(survey);

    return {
      survey
    };
  },
  methods: {
    initialiseSurvey() {
      //Create the model and pass it into VueSJ Survey component
      var survey = new Model(this.jsondata_quest);
      survey.applyTheme(surveyTheme);
      //lang needed for strings and images
      survey.locale = this.$i18n.locale;

      // Set the survey's data to the data stored in the store
      this.restoreSurveyReply(survey);
      // Save survey results when users change a question value...
      survey.onValueChanged.add(this.updateSurveyReply);
      // ... and switch to the next page
      survey.onCurrentPageChanged.add(this.updateSurveyReply);
      // Submit final survey results when users click on the 'Complete' button
      survey.onComplete.add(result => {
        this.sendResultToServer(result.data);
      });
      //Jump problem center-right
      //https://github.com/surveyjs/survey-library/issues/1836
      //https://surveyjs.answerdesk.io/ticket/details/t2953/scroll-up-for-more-than-1-page-survey
      // There is apparently an incompatibility in between vuetify and surveyJs
      // We manually move at each new page render the view to something close to the title of the suvey
      // A bit empirical, should be checked how to adapt for any screen size ?
      survey.onAfterRenderPage.add(function () {
        window.scrollTo(0, 650);
      });

      return survey;
    },
    setSurveyImages(survey) {
      //set base64 images if applicable
      if (this.jsondata_img) {
        // add images which are the same for all languages
        if (Object.keys(this.jsondata_img).includes('all')) {
          const imgListAll = this.jsondata_img['all'];
          for (const img of imgListAll) {
            survey.getQuestionByName(img['name']).imageLink = img['imagelink'];
          }
        }
        // add language specific images
        if (Object.keys(this.jsondata_img).includes(this.$i18n.locale)) {
          for (const img of this.jsondata_img[this.$i18n.locale]) {
            if (!Array.isArray(img['name'])) {
              survey.getQuestionByName(img['name']).imageLink = img['imagelink'];
            } else {
              for (const imgName of img['name']) {
                survey.getQuestionByName(imgName).imageLink = img['imagelink'];
              }
            }
          }
        }
      }
    },
    /**
     * Send the final results after the participants clicked 'Complete' to the respective endpoint for storage
     * @param {object} survey_results - The surveyJS results stored as JSON
     */
    async sendResultToServer(survey_results) {
      try {
        var payload = {
          survey_public_id: this.survId,
          survey_data: {
            language_chosen: this.language_chosen,
            survey_results: survey_results,
          },
        };
        await this.$store.dispatch("FETCH_SEND_SURVEY_RESULTS", { payload });
        this.$store.dispatch('clearSurveyReply');
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },
    updateSurveyReply(survey) {
      const surveyReply = survey.data;
      surveyReply.pageNo = survey.currentPageNo;
      this.$store.dispatch('updateSurveyReply', { surveyId: this.survId, surveyReply });
    },
    restoreSurveyReply(survey) {
      const prevId = this.$store.state.QuestionnaireStore.surveyId;
      const prevData = this.$store.state.QuestionnaireStore.surveyReply;
      if (prevId === this.survId && prevData) {
        survey.data = prevData;
        if (prevData.pageNo) {
          survey.currentPageNo = prevData.pageNo;
        }
      } else if (prevId || prevData) {
        this.$store.dispatch('clearSurveyReply');
      }
    }
  },
  computed: {
    /**
     * The language as chosen by the user
     */
    language_chosen() {
      return this.$i18n.locale;
    },
  },
  watch: {
    language_chosen: function () {
      this.survey.locale = this.$i18n.locale;
    },
  },
};
</script>
